/* @import url("https://fonts.googleapis.com/css2?family=Lato&family=Roboto:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Oswald&display=swap');
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  /* font-family: "Lato", sans-serif; */
  /* font-family: "Roboto", sans-serif; */
  /* font-family: 'Lato'!important; */
  /* font-family: "Public Sans", sans-serif; */
  font-family: 'Oswald', sans-serif;

  font-style: normal;
  list-style: none;
  text-decoration: none;
  scroll-margin-top: 87px;
}
.cursor {
  cursor: pointer;
}
body {
  font-weight: 400;
  min-height: 100vh;
  scroll-behavior: smooth;
  font-style: normal;
  overflow-x: hidden;
  /* font-family: "Lato", sans-serif; */
}

body a {
  text-decoration: none;
  color: black;
}
body a:hover {
  color: #7fcd91;
  text-decoration: none;
}

body h1 {
  font-weight: 400;
  font-size: 50px;
  line-height: 65px;
  font-style: normal;
  /* font-family: "Lato", sans-serif; */
  color: #323232;
}
body h2 {
  /* font-family: "Roboto"; */
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 65px;
  color: #323232;
}
body p {
  /* font-family: "Roboto", sans-serif; */
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-style: normal;
  /* color: #adadad; */
}

body .all-btn {
  border: none;
  background: radial-gradient(
      80.56% 92.2% at 13.61% 25.41%,
      #7fcd91 22.71%,
      #A52929 100%
    ),
    #A52929;
  color: #ffffff;
  font-size: 16px;
  line-height: 18px;
  padding: 15px 20px;
  border-radius: 0;
  transition: all 0.3s ease-in-out !important;
  outline: none !important;
  width: auto;
  border: 1px solid#7fcd91;
}
body .all-btn:hover {
  color: #ffffff;
}
body .btn-prime {
  background-color: #ffffff;
  /* border: 2px solid #7fcd91; */
  color: #A52929;
  border-radius: 10px !important;
  font-size: 18px;
  font-weight: 600;
  padding: 1% 2%;
  outline: none !important;
  min-width: 80px;
  width: auto;
}
body .btn-secondary {
  background-color: #fff;
  border: 2px solid #13253d;
  color: #13253d;
  /* color: #A52929; */
  font-size: 15px;
  font-weight: 600;
  border-radius: 7px;
  padding: 1% 2%;
  outline: none !important;
}
body .btn-secondary-dark {
  background-color: #13253d;
  border: 2px solid #13253d;

  color: #fff;
  /* color: #A52929; */
  font-size: 15px;
  font-weight: 600;
  border-radius: 7px;
  padding: 1% 2%;
  outline: none !important;
}
body .btn-secondary:disabled {
  background-color: #13253d57;
  border: 2px solid #13253d57;
  cursor: not-allowed;

  color: #eee;
  /* color: #A52929; */
  font-size: 15px;
  font-weight: 600;
  border-radius: 7px;
  padding: 1% 2%;
  outline: none !important;
}
body .btn-danger {
  background-color: #fff;
  border: 2px solid #f11111;

  color: #f11111;
  /* color: #A52929; */
  font-size: 18px;
  font-weight: 600;
  /* border-radius: 7px; */
  padding: 1% 2%;
  outline: none !important;
}
.dark {
  background: #13253d !important;
  background-color: #13253d !important;
  color: white !important;
}
body .txt-secondary {
  /* background-color: #13253D; */
  /* border: none; */
  color: #13253d;
  /* color: #A52929; */
  font-size: 14px;
  font-weight: 600;
}
.btn:focus,
.btn-prime:focus,
.all-btn {
  box-shadow: none !important;
}

.btn-prime body {
  overflow-x: hidden !important;
}
.error-border {
  border: 1px solid red !important;
}
.success-border {
  border: 1px solid #79bda7 !important;
}
.w80 {
  width: 80% !important;
}
.w50 {
  width: 50% !important;
}
.box-shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.11);
}
.prm-color {
  color: #A52929;
}
.bg-prm {
  background: #7fcd91 22.71%;
}
.prm-bg-color {
}
.bg-second {
  background-color: #A52929 !important;
}
.w70 {
  width: 70% !important;
}
.w75 {
  width: 75% !important;
}
.w60 {
  width: 60% !important;
}
.w55 {
  width: 55% !important;
}
.prm-bg-color {
  background-color: #e8fff9 !important;
}
.dark-color {
  color: #323232;
}
.fw500 {
  font-weight: 500 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.grey-color {
  color: #adadad !important;
}
.fs12 {
  font-size: 12px !important;
}
.fs10 {
  font-size: 10px !important;
}
.fs14 {
  font-size: 14px !important;
}
.fs16 {
  font-size: 16px !important;
}
.fs18 {
  font-size: 18px !important;
}

.pointer,
.pointer * {
  cursor: pointer !important;
}
.op0 {
  opacity: 0;
}
.op1 {
  opacity: 1;
}
.smallbtn {
  border: 0px;
  background: #A52929;
  color: #fff;
  font-size: 12px;
  padding: 5px 15px;
  border-radius: 0px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
}
.box-shadow-none {
  box-shadow: none !important;
}

.Loader-glass {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  z-index: 1;
  /* background-color: red; */
  /* height: 100vh;
  width: 100vw; */
}
.dashboard-grey-bg {
  background: linear-gradient(
    98.25deg,
    #495a70 5.38%,
    rgba(73, 90, 112, 0) 213.21%
  ) !important;
  backdrop-filter: blur(50px) !important;
}
.ls1 {
  letter-spacing: 0.5px !important;
}
.Loader-glass .loader-title {
  font-size: 50px;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  color: #3f4d6a;
  position: absolute;
  /* font-family: "Lato", sans-serif; */
  bottom: -10px;
  left: -88px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.searchStyle {
  /* border: none; */
  border: 1px solid #eee;
  border-radius: 2px !important;
}
.card-header {
  background-color: white;
  background: white !important;
}
.btn.btn-success {
  background-color: #A52929;
  padding: 10px 46px;
  border: 1px solid #A52929;
}
.btn.btn-primary {
  border: 1px solid #A52929;
  background-color: #fff;
  color: #A52929;
  padding: 10px 46px;
  /* border: none; */
}
.btn-primary:hover{
  background: #A52929 !important;
}
.kuber {
  position: relative;
}
.lightgrey-color {
  color: #adadad !important;
}
.lightgrey-bg {
  background-color: #adadad !important;
}
.lightgrey-border {
  border: 1px solid #adadad !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.br10 {
  border-radius: 10px !important;
}
.br5 {
  border-radius: 5px !important;
}
.br50 {
  border-radius: 50%;
}
.br0 {
  border-radius: 0px !important;
}

.darkblue-color {
  color: #122348 !important;
}
.darkbluebg-color {
  background-color: #13253d;
}
.prmgreen-bg{
  
  background-color: #A52929 !important;
}
.btn.model-btn {
  border: 1px solid #A52929;
  background-color: #A52929;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
